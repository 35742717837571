var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.information
    ? _c(
        "section",
        [
          _c(
            "b-overlay",
            { attrs: { show: _vm.loading, rounded: "sm" } },
            [
              _c("h5", { staticClass: "mt-3" }, [
                _vm._v(_vm._s(_vm.$t("NAME"))),
              ]),
              _c(
                "div",
                { staticClass: "mt-1" },
                [
                  _c("b-form-input", {
                    attrs: {
                      trim: "",
                      required: "",
                      type: "text",
                      name: "name",
                    },
                    model: {
                      value: _vm.payload.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.payload, "name", $$v)
                      },
                      expression: "payload.name",
                    },
                  }),
                ],
                1
              ),
              _c("h5", { staticClass: "mt-3" }, [
                _vm._v(_vm._s(_vm.$t("ADMINLEVEL.TITLE"))),
              ]),
              _c(
                "div",
                { staticClass: "mt-1" },
                [
                  _c("b-form-select", {
                    attrs: { options: _vm.adminOptions },
                    model: {
                      value: _vm.payload.is_admin,
                      callback: function ($$v) {
                        _vm.$set(_vm.payload, "is_admin", $$v)
                      },
                      expression: "payload.is_admin",
                    },
                  }),
                ],
                1
              ),
              _c("h5", { staticClass: "mt-3" }, [
                _vm._v(_vm._s(_vm.$t("LANGUAGE.TITLE"))),
              ]),
              _c(
                "div",
                { staticClass: "mt-1" },
                [
                  _c(
                    "b-form-select",
                    {
                      attrs: { name: "edit_invite_select_language" },
                      model: {
                        value: _vm.payload.language,
                        callback: function ($$v) {
                          _vm.$set(_vm.payload, "language", $$v)
                        },
                        expression: "payload.language",
                      },
                    },
                    _vm._l(_vm.sefos_locales, function (value, key) {
                      return _c(
                        "b-form-select-option",
                        { key: key, attrs: { value: key } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("LANGUAGE." + key)) +
                              "\n          "
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c("h5", { staticClass: "mt-3" }, [
                _vm._v(_vm._s(_vm.$t("AUTH_METHODS.SELECT_METHOD"))),
              ]),
              _c(
                "div",
                { staticClass: "mt-1" },
                [
                  _c("b-form-select", {
                    staticClass: "mb-2",
                    attrs: { options: _vm.AuthTypeOptions },
                    on: { input: _vm.changedAuth },
                    model: {
                      value: _vm.selectedIndex,
                      callback: function ($$v) {
                        _vm.selectedIndex = $$v
                      },
                      expression: "selectedIndex",
                    },
                  }),
                  _vm.currentAuthType == "se-eid"
                    ? _c("Pnr", {
                        on: { valid: _vm.setValidated },
                        model: {
                          value: _vm.payload.data.pnr,
                          callback: function ($$v) {
                            _vm.$set(_vm.payload.data, "pnr", $$v)
                          },
                          expression: "payload.data.pnr",
                        },
                      })
                    : _vm._e(),
                  _vm.currentAuthType == "password-sms"
                    ? _c("Phone", {
                        attrs: { required: "" },
                        on: { valid: _vm.setValidated },
                        model: {
                          value: _vm.payload.data.identifier,
                          callback: function ($$v) {
                            _vm.$set(_vm.payload.data, "identifier", $$v)
                          },
                          expression: "payload.data.identifier",
                        },
                      })
                    : _vm._e(),
                  _vm.showIdentifier
                    ? _c("Identifier", {
                        attrs: { placeholder: _vm.$t("UNIQUE_IDENTIFIER") },
                        on: { valid: _vm.setValidated },
                        model: {
                          value: _vm.payload.data.identifier,
                          callback: function ($$v) {
                            _vm.$set(_vm.payload.data, "identifier", $$v)
                          },
                          expression: "payload.data.identifier",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c("hr", { staticClass: "p-0 m-0 mt-2 mb-2" }),
              _c(
                "b-button",
                {
                  staticClass: "btn-fill",
                  attrs: { disabled: !_vm.canUpdate, variant: "primary" },
                  on: { click: _vm.updateInvite },
                },
                [_vm._v(_vm._s(_vm.$t("SAVE")))]
              ),
              _c(
                "b-button",
                {
                  staticClass: "btn-fill float-right",
                  attrs: { variant: "danger" },
                  on: { click: _vm.removeSubmit },
                },
                [_vm._v(_vm._s(_vm.$t("REMOVE")))]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }